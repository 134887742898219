@charset "UTF-8";
@keyframes loader-spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/** initial setup **/
.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.nano > .nano-content:focus {
  outline: thin dotted; }

.nano > .nano-content::-webkit-scrollbar {
  display: none; }

.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block; }

.nano > .nano-pane {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 10px;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden\9;
  /* Target only IE7 and IE8 with this hack */
  opacity: .01;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 5px; }

.nano > .nano-pane > .nano-slider {
  background: #444;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  margin: 0 1px;
  border-radius: 3px; }

.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
  visibility: visible\9;
  /* Target only IE7 and IE8 with this hack */
  opacity: 0.99; }

html {
  min-width: 320px; }

body {
  background-color: #f3f3f3;
  color: #000000;
  max-width: 1200px;
  min-width: 320px;
  font-family: "AkzidenzGroteskPro", sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh; }
  body._adaptive {
    min-width: 320px;
    max-width: none; }
  body._white {
    background-color: white; }

main {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto; }

input,
button {
  font-family: inherit;
  font-size: inherit; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Light.otf");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Regular.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Light.otf");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Md.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Bold.otf");
  font-weight: 700;
  font-style: normal; }

.page-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh; }

.page-layout {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .page-layout {
      padding-left: 20px;
      padding-right: 20px; } }

.page-title {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 23px;
  letter-spacing: 0.3px; }

.page-subtitle {
  line-height: 23px;
  font-weight: 500;
  margin: 24px 0 20px; }
  .page-subtitle span, .page-subtitle img {
    vertical-align: middle; }
  .page-subtitle img {
    margin-right: 5px; }

.content {
  padding-top: 20px;
  background: white;
  padding-bottom: 20px; }
  .content__inner {
    max-width: 450px; }
    .content__inner_size-2 {
      max-width: 540px; }
    .content__inner_size-3 {
      max-width: 600px; }
  .content__group-title {
    color: #273748;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px; }
  .content__row {
    font-size: 0; }
  .content__col {
    display: inline-block;
    vertical-align: top;
    width: 100%; }
    @media (min-width: 768px) {
      .content__col {
        margin-right: 2%;
        width: 49%; } }
    .content__col:last-child {
      margin-right: 0; }
  .content__panel {
    border: 1px solid #e7e7e7;
    padding: 14px; }
    .content__panel .form__label {
      padding-right: 10px;
      vertical-align: middle;
      width: 100%; }
      @media (min-width: 992px) {
        .content__panel .form__label {
          width: 22%; } }
    .content__panel .form__field {
      width: 100%; }
      @media (min-width: 992px) {
        .content__panel .form__field {
          width: 78%; } }
    .content__panel > .form__row:last-child {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .content__panel_right-offset {
        padding-right: 45px; } }

p {
  margin-top: 22px;
  font-size: 14px;
  letter-spacing: 0.2px; }

.link {
  color: black;
  line-height: 23px;
  display: inline-block;
  text-decoration: underline; }
  .link_black {
    color: #333;
    text-decoration: underline; }
  .link_no-underline {
    text-decoration: none; }

table {
  width: 100%; }

.sort-link {
  padding-right: 10px;
  font-size: 14px;
  position: relative; }
  .sort-link:before {
    background-image: url(sprite.png);
    background-position: -286px -177px;
    width: 9px;
    height: 14px;
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .sort-link_up-active:before {
    background-image: url(sprite.png);
    background-position: -276px -247px;
    width: 9px;
    height: 14px; }
  .sort-link_down-active:before {
    background-image: url(sprite.png);
    background-position: -286px -157px;
    width: 9px;
    height: 14px; }
  .sort-link_down:before {
    background-image: url(sprite.png);
    background-position: -234px -206px;
    width: 9px;
    height: 14px; }

.add-link {
  background: none;
  border: none;
  color: black;
  font-weight: 500;
  line-height: 23px;
  cursor: pointer;
  padding: 0;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  position: relative;
  min-height: 26px;
  padding-left: 26px;
  padding-top: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .add-link:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(sprite.png);
    background-position: -225px 0px;
    width: 26px;
    height: 26px; }
  .add-link img, .add-link span {
    display: inline-block;
    vertical-align: middle; }
  .add-link span {
    margin-left: 15px; }
  .add-link_add-user:before {
    background-image: url(sprite.png);
    background-position: -225px -130px;
    width: 26px;
    height: 26px; }
  .add-link_remove-user:before {
    background-image: url(sprite.png);
    background-position: 0px -168px;
    width: 26px;
    height: 27px; }
  .add-link_import-user:before {
    background-image: url(sprite.png);
    background-position: -251px 0px;
    width: 25px;
    height: 23px; }

.auth-page {
  padding-bottom: 20px; }
  .auth-page__avatar {
    margin-bottom: 20px; }
  .auth-page__row {
    margin-top: 30px; }

.nano {
  width: 100%;
  height: 500px; }
  .nano .nano-content {
    padding: 10px; }
  .nano .nano-pane {
    display: block;
    opacity: 1;
    border-radius: 0;
    width: 12px;
    background-color: #e7e7e7; }

.nano > .nano-pane > .nano-slider {
  background-color: #8d9298;
  border-radius: 0; }

.title-back {
  color: #273748;
  font-size: 16px;
  display: block;
  text-decoration: none;
  min-height: 26px;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 500;
  padding-left: 40px;
  position: relative; }
  .title-back:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url(sprite.png);
    background-position: -104px -195px;
    width: 26px;
    height: 26px; }

.expand__link {
  padding-left: 30px;
  cursor: pointer;
  position: relative; }
  .expand__link:before {
    content: '';
    background-image: url(sprite.png);
    background-position: -276px -211px;
    width: 17px;
    height: 9px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .expand__link_active:before {
    background-image: url(sprite.png);
    background-position: -276px -238px;
    width: 17px;
    height: 9px; }
  .expand__link_no-open {
    background: none;
    cursor: default; }
  .expand__link_filter {
    min-height: 22px;
    line-height: 22px; }
    .expand__link_filter:before {
      background-image: url(sprite.png);
      background-position: -152px -239px;
      width: 20px;
      height: 20px; }
  .expand__link_filter.expand__link_active {
    font-weight: bold;
    margin-bottom: 15px; }

.expand__content {
  display: none; }

.expand__link_active + .expand__content {
  display: block; }

.expand .form__row {
  margin-bottom: 10px; }

.expand__row {
  margin-bottom: 10px; }

.expand__content > .expand__link:first-child {
  margin-left: 15px; }

.expand__content > .expand__content {
  margin-left: 15px; }

.expand__content > .expand__content {
  margin-left: 30px; }

.apps__name {
  display: table-cell;
  vertical-align: middle;
  width: 295px;
  font-size: 14px;
  padding-left: 50px;
  font-weight: 500;
  line-height: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px; }

.apps__icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: block;
  width: 35px;
  height: 35px;
  background-size: cover;
  margin-top: -5px; }

.apps__text {
  vertical-align: middle;
  display: table-cell; }

.apps__row {
  border: 1px solid #e7e7e7;
  border-bottom: none;
  min-height: 70px; }
  .apps__row:last-child {
    border-bottom: 1px solid #e7e7e7; }

.apps__header {
  height: 70px;
  display: table;
  width: calc(100% - 10px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: 10px;
  padding-right: 10px; }

.apps__content {
  padding-left: 40px;
  padding-right: 10px;
  padding-bottom: 20px; }
  .apps__content p {
    margin-top: 0;
    margin-bottom: 15px; }
    .apps__content p:last-child {
      margin-bottom: 0; }

ul {
  padding-left: 17px; }

.tag {
  background-color: #dcddde;
  padding: 3px 5px;
  margin-right: 2px; }
  .tag_bold {
    font-weight: bold; }

.page_conceptino .auth {
  max-width: none;
  background: #fff; }

@font-face {
  .page_conceptino .auth {
    font-family: 'Open Sans';
    src: url(../fonts/OpenSans-Regular.ttf);
    font-weight: 400;
    font-style: normal; } }

@font-face {
  .page_conceptino .auth {
    font-family: 'Open Sans';
    src: url(../fonts/OpenSans-Bold.ttf);
    font-weight: 700;
    font-style: normal; } }
  .page_conceptino .auth .main-header {
    position: relative;
    background: url(../images/foreign-auth/header-bg.jpg) no-repeat top center;
    font-family: 'Open Sans', sans-serif; }
  .page_conceptino .auth .main-footer {
    background-color: #f5f5f5;
    font-family: 'Open Sans', sans-serif; }
  .page_conceptino .auth .page-container {
    max-width: none; }
  .page_conceptino .auth .main-nav {
    display: none; }
  .page_conceptino .auth .main-header__aside, .page_conceptino .auth .main-header__logo {
    display: none; }
  .page_conceptino .auth .main-header {
    position: relative;
    height: 150px;
    background: url(../images/foreign-auth/header-bg.jpg) no-repeat top center;
    font-family: 'Open Sans', sans-serif; }
  .page_conceptino .auth main {
    font-family: 'Open Sans', sans-serif;
    padding-top: 100px;
    padding-bottom: 80px; }
  @media (min-width: 768px) {
    .page_conceptino .auth .main-header {
      height: 200px; } }
  @media (min-width: 992px) {
    .page_conceptino .auth .main-header {
      height: 250px; } }
  .page_conceptino .auth .main-header:after {
    content: '';
    width: 120px;
    height: 120px;
    background: #fff url(../images/foreign-auth/company-logo.svg) no-repeat center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: -60px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .page_conceptino .auth .main-footer__text {
    display: none; }
  .page_conceptino .auth .main-footer {
    height: 80px;
    background-color: #f5f5f5;
    font-family: 'Open Sans', sans-serif; }
  .page_conceptino .auth .content__inner {
    max-width: 345px;
    margin: 0 auto; }
  .page_conceptino .auth .page-subtitle, .page_conceptino .auth .page-title {
    font-size: 0;
    text-align: center; }
  .page_conceptino .auth .page-title:before {
    content: 'Система \A Управления Знаниями';
    white-space: pre;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
    color: #352e41; }
  .page_conceptino .auth .form {
    display: none; }
  .page_conceptino .auth .page-subtitle {
    margin-top: 40px; }
  .page_conceptino .auth .page-subtitle:before {
    content: 'В настоящий момент авторизация доступна только через социальные сети.';
    font-size: 14px;
    text-align: center;
    color: #352e41;
    line-height: 1.2em; }
  .page_conceptino .auth .socials + p {
    display: none; }
  .page_conceptino .auth .socials {
    font-size: 0;
    text-align: center;
    margin-top: 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .page_conceptino .auth .socials__item {
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 60px;
    margin-right: 20px; }
  .page_conceptino .auth .socials__item .icon {
    display: none; }
  .page_conceptino .auth .socials__item img {
    display: none; }
  .page_conceptino .auth .socials__item:last-child {
    margin-right: 0; }
  .page_conceptino .auth .socials__item:first-child {
    background: url(../images/foreign-auth/fb.svg) no-repeat center; }
  .page_conceptino .auth .socials__item:nth-child(2) {
    background: url(../images/foreign-auth/google.svg) no-repeat center; }
  .page_conceptino .auth .socials__item:nth-child(3), .page_conceptino .auth .socials__item:nth-child(6) {
    display: none; }
  .page_conceptino .auth .socials__item:nth-child(5) {
    background: url(../images/foreign-auth/yandex.svg) no-repeat center;
    margin-right: 0; }
  .page_conceptino .auth .socials__item:nth-child(4) {
    background: url(../images/foreign-auth/vk.svg) no-repeat center; }

.page_grey {
  color: #000000; }
  .page_grey .select:before {
    border-top-color: #96a0ab; }
  .page_grey .btn {
    color: #546475;
    border: 1px solid #546475; }
    .page_grey .btn:hover {
      background: #546475;
      color: white; }
  .page_grey .checkbox__text {
    color: #273748; }
  .page_grey .form__label {
    color: #273748; }
  .page_grey .form__remove-row {
    background-image: url(sprite.png);
    background-position: -104px -168px;
    width: 26px;
    height: 26px; }
  .page_grey .form__add-more {
    background-image: url(sprite.png);
    background-position: -225px 0px;
    width: 26px;
    height: 26px; }
  .page_grey .form__remove-row {
    background-image: url(sprite.png);
    background-position: -104px -168px;
    width: 26px;
    height: 26px; }
  .page_grey .input {
    color: #273748; }
  .page_grey .lang-selector__item {
    color: #fefeff; }
  .page_grey .main-footer {
    background: #273748;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .page_grey .main-footer__text {
      color: #dae1e8; }
  .page_grey .main-header {
    -webkit-box-shadow: none;
            box-shadow: none;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(51, 73, 96, 0.7)), to(rgba(51, 73, 96, 0.7))), url("../images/header-bg.jpg");
    background-image: linear-gradient(to right, rgba(51, 73, 96, 0.7) 0%, rgba(51, 73, 96, 0.7) 100%), url("../images/header-bg.jpg");
    background-size: cover; }
    .page_grey .main-header__logo span {
      color: white; }
  .page_grey .main-nav {
    -webkit-box-shadow: none;
            box-shadow: none;
    background: #273748;
    color: #000000; }
    @media (min-width: 768px) {
      .page_grey .main-nav__aside:after {
        border-right: 1px dotted white; } }
    .page_grey .main-nav__main {
      background: #273748; }
    .page_grey .main-nav__item {
      color: white; }
  .page_grey .pagination {
    border-color: #bcbdbd; }
    .page_grey .pagination__item {
      border-color: #bcbdbd;
      color: #273748; }
  .page_grey .popup .mfp-close {
    background: no-repeat center;
    background-image: url(sprite.png);
    background-position: -104px -168px;
    width: 26px;
    height: 26px; }
  .page_grey .role-selector__item {
    color: white; }
  .page_grey .role-selector__link {
    background: url("../images/icons/checker-bg.png") no-repeat center; }
  .page_grey .role-selector__checker span {
    background: url("../images/icons/checker-handler.png") no-repeat center; }
  .page_grey .user-nav__main {
    color: white; }
  .page_grey .checkbox__icon {
    border: 1px solid #aab2bc; }
  .page_grey .checkbox input:checked + .checkbox__icon {
    background-image: url(../images/icons/check-only.png); }
  .page_grey .search-field__field, .page_grey .search-field__button {
    border-color: #546475; }
  .page_grey .icon_logout {
    background-image: url(sprite.png);
    background-position: -276px 0px;
    width: 19px;
    height: 20px; }
  .page_grey .icon_ask {
    background-image: url(sprite.png);
    background-position: -251px -69px;
    width: 23px;
    height: 23px; }
  .page_grey .icon_lk-empty {
    background-image: url(sprite.png);
    background-position: -128px -35px;
    width: 35px;
    height: 35px; }
  .page_grey .icon_fb {
    background-image: url(sprite.png);
    background-position: -108px -97px;
    width: 20px;
    height: 20px; }
  .page_grey .icon_google {
    background-image: url(sprite.png);
    background-position: -276px -20px;
    width: 19px;
    height: 20px; }
  .page_grey .icon_twitter {
    background-image: url(sprite.png);
    background-position: -251px -92px;
    width: 25px;
    height: 20px; }
  .page_grey .icon_vk {
    background-image: url(sprite.png);
    background-position: 0px -221px;
    width: 33px;
    height: 18px; }
  .page_grey .icon_ya {
    background-image: url(sprite.png);
    background-position: -276px -157px;
    width: 10px;
    height: 20px; }
  .page_grey .icon_mail {
    background-image: url(sprite.png);
    background-position: -251px -153px;
    width: 20px;
    height: 21px; }
  .page_grey .icon_bitbucket {
    background-image: url(sprite.png);
    background-position: -276px -80px;
    width: 18px;
    height: 20px; }
  .page_grey .icon_university2035 {
    background-image: url(sprite.png);
    background-position: -52px -239px;
    width: 20px;
    height: 20px; }
  .page_grey .icon_window {
    background-image: url(sprite.png);
    background-position: -212px -239px;
    width: 20px;
    height: 20px; }
  .page_grey .icon_github {
    background-image: url(sprite.png);
    background-position: -251px -215px;
    width: 20px;
    height: 20px; }
  .page_grey .icon_edit-layers {
    background-image: url(sprite.png);
    background-position: -112px -239px;
    width: 20px;
    height: 20px; }
  .page_grey .icon_trash {
    background-image: url(sprite.png);
    background-position: -276px -100px;
    width: 16px;
    height: 20px; }
  .page_grey .icon_edit-users {
    background-image: url(sprite.png);
    background-position: -232px -239px;
    width: 21px;
    height: 19px; }
  .page_grey .icon_edit-password {
    background-image: url(sprite.png);
    background-position: -33px -221px;
    width: 34px;
    height: 17px; }
  .page_grey .icon_pencil {
    background-image: url(sprite.png);
    background-position: -72px -239px;
    width: 20px;
    height: 20px; }
  .page_grey .icon_update-password {
    background-image: url(sprite.png);
    background-position: -128px -105px;
    width: 32px;
    height: 13px; }
  .page_grey .icon_prev {
    background-image: url(sprite.png);
    background-position: -289px -197px;
    width: 5px;
    height: 10px; }
  .page_grey .icon_next {
    background-image: url(sprite.png);
    background-position: -271px -142px;
    width: 5px;
    height: 10px; }
  .page_grey .icon_end-left {
    background-image: url(sprite.png);
    background-position: -208px -168px;
    width: 9px;
    height: 10px; }
  .page_grey .icon_end-right {
    background-image: url(sprite.png);
    background-position: -193px -156px;
    width: 9px;
    height: 10px; }
  .page_grey .icon_search {
    background-image: url(sprite.png);
    background-position: -52px -195px;
    width: 26px;
    height: 26px; }
  .page_grey .icon_clip {
    background-image: url(sprite.png);
    background-position: -225px -181px;
    width: 24px;
    height: 25px; }
  .page_grey .add-link:before {
    background-image: url(sprite.png);
    background-position: -225px 0px;
    width: 26px;
    height: 26px; }
  .page_grey .add-link_add-user:before {
    background-image: url(sprite.png);
    background-position: -225px -130px;
    width: 26px;
    height: 26px; }
  .page_grey .add-link_remove-user:before {
    background-image: url(sprite.png);
    background-position: 0px -168px;
    width: 26px;
    height: 27px; }
  .page_grey .add-link_import-user:before {
    background-image: url(sprite.png);
    background-position: -251px 0px;
    width: 25px;
    height: 23px; }
  .page_grey .sort-link:before {
    background-image: url(sprite.png);
    background-position: -286px -177px;
    width: 9px;
    height: 14px; }
  .page_grey .sort-link_down:before {
    background-image: url(sprite.png);
    background-position: -234px -206px;
    width: 9px;
    height: 14px; }
  .page_grey .pagination-nav__item {
    border: 1px solid #606f7f; }
    .page_grey .pagination-nav__item:before {
      background-image: url(sprite.png);
      background-position: -208px -168px;
      width: 9px;
      height: 10px; }
    .page_grey .pagination-nav__item:nth-child(2):before {
      background-image: url(sprite.png);
      background-position: -289px -197px;
      width: 5px;
      height: 10px; }
    .page_grey .pagination-nav__item:nth-child(3):before {
      background-image: url(sprite.png);
      background-position: -271px -142px;
      width: 5px;
      height: 10px; }
    .page_grey .pagination-nav__item:nth-child(4):before {
      background-image: url(sprite.png);
      background-position: -193px -156px;
      width: 9px;
      height: 10px; }
  .page_grey .expand__link:before {
    background-image: url(sprite.png);
    background-position: -276px -211px;
    width: 17px;
    height: 9px; }
  .page_grey .expand__link_active:before {
    background-image: url(sprite.png);
    background-position: -276px -238px;
    width: 17px;
    height: 9px; }
  .page_grey .expand__link_filter:before {
    background-image: url(sprite.png);
    background-position: -152px -239px;
    width: 20px;
    height: 20px; }
  .page_grey .title-back {
    color: #273748; }
    .page_grey .title-back:before {
      background-image: url(sprite.png);
      background-position: -104px -195px;
      width: 26px;
      height: 26px; }
  .page_grey .document-upload {
    color: #728496; }
    .page_grey .document-upload__file {
      color: #728496; }
  .page_grey .data-table th {
    background-color: #bcbdbd; }

@font-face {
  font-family: 'ChevinPro';
  src: url(../fonts/ChevinPro-Light.ttf);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'ChevinPro';
  src: url(../fonts/ChevinPro-Medium.ttf);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'ChevinPro';
  src: url(../fonts/ChevinPro-Bold.ttf);
  font-weight: 700;
  font-style: normal; }

.page_nti .auth {
  font-family: 'ChevinPro', Arial, sans-serif;
  max-width: none;
  background: #f5f5f5; }
  .page_nti .auth .main-header {
    display: none; }
  .page_nti .auth .main-footer {
    display: none; }
  .page_nti .auth .page-container {
    max-width: 1600px; }
  .page_nti .auth .main-nav {
    display: none; }
  .page_nti .auth .main-header__aside, .page_nti .auth .main-header__logo {
    display: none; }
  .page_nti .auth main {
    padding-top: 255px;
    padding-bottom: 80px; }
  .page_nti .auth .main-footer__text {
    display: none; }
  .page_nti .auth .main-footer {
    height: 80px;
    background-color: #f5f5f5;
    font-family: 'Open Sans', sans-serif; }
  .page_nti .auth .content__inner {
    margin: 0 auto;
    text-align: center;
    max-width: none; }
  .page_nti .auth .page-subtitle, .page_nti .auth .page-title {
    font-size: 0;
    text-align: center; }
  .page_nti .auth .page-title:before {
    content: '';
    background: url("../images/nti-logo.png") no-repeat center;
    width: 237px;
    height: 123px;
    display: block;
    margin: 0 auto; }
  .page_nti .auth .form {
    display: none; }
  .page_nti .auth .page-subtitle {
    letter-spacing: -0.3px;
    margin-bottom: 22px;
    display: none; }
  .page_nti .auth .nti-link-main {
    letter-spacing: -0.3px;
    margin-bottom: 28px;
    display: block;
    font-size: 18px;
    text-align: center;
    color: #808080;
    line-height: 1.2em;
    text-decoration: none; }
  .page_nti .auth .socials + p {
    display: none; }
  .page_nti .auth .socials {
    font-size: 0; }
  .page_nti .auth .socials__item {
    display: none; }
  .page_nti .auth .socials .socials__item.socials__item_nti {
    display: block;
    margin: 71px auto 107px;
    font-family: 'ChevinPro', Arial, sans-serif;
    font-size: 32px;
    width: 410px;
    height: 100px;
    color: white;
    text-transform: uppercase;
    background: #e52127;
    border-color: #e52127;
    text-decoration: none;
    padding-top: 44px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .page_nti .auth .socials .socials__item.socials__item_nti:before {
      content: 'Выполнить вход'; }
    .page_nti .auth .socials .socials__item.socials__item_nti span {
      display: none; }
    .page_nti .auth .socials .socials__item.socials__item_nti:hover {
      background-color: #be1e23;
      border-color: #be1e23; }
  .page_nti .auth .nti-link {
    font-size: 18px;
    color: #e52127;
    text-align: center;
    text-decoration: none; }

.page_white .select:before,
.page_nti .select:before {
  border-top-color: black; }

.page_white .btn,
.page_nti .btn {
  color: black;
  border: 1px solid black; }
  .page_white .btn:hover,
  .page_nti .btn:hover {
    color: white;
    background: black; }

.page_white .checkbox__text,
.page_nti .checkbox__text {
  color: black; }

.page_white .form__label,
.page_nti .form__label {
  color: black; }

.page_white .form__remove-row,
.page_nti .form__remove-row {
  background-image: url(sprite.png);
  background-position: -78px -168px;
  width: 26px;
  height: 26px; }

.page_white .form__add-more,
.page_nti .form__add-more {
  background-image: url(sprite.png);
  background-position: -225px -104px;
  width: 26px;
  height: 26px; }

.page_white .form__remove-row,
.page_nti .form__remove-row {
  background-image: url(sprite.png);
  background-position: -78px -168px;
  width: 26px;
  height: 26px; }

.page_white .input,
.page_nti .input {
  color: black; }

.page_white .lang-selector__item,
.page_nti .lang-selector__item {
  color: #2b2b2b; }

.page_white .main-footer,
.page_nti .main-footer {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f8f8f8), to(#d4d3d3));
  background-image: linear-gradient(0deg, #f8f8f8 0%, #d4d3d3 100%);
  -webkit-box-shadow: 0 1px 3.88px rgba(186, 186, 186, 0.75);
          box-shadow: 0 1px 3.88px rgba(186, 186, 186, 0.75); }
  .page_white .main-footer__text,
  .page_nti .main-footer__text {
    color: #565656; }

.page_white .main-header,
.page_nti .main-header {
  background-image: url("../images/header-bg-2.png"), -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#f8f8f8));
  background-image: url("../images/header-bg-2.png"), linear-gradient(90deg, #e6e6e6 0%, #f8f8f8 100%);
  -webkit-box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
          box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75); }
  .page_white .main-header__logo span,
  .page_nti .main-header__logo span {
    color: #e86d00; }

.page_white .main-nav,
.page_nti .main-nav {
  background-image: -webkit-gradient(linear, left top, right top, from(#f8f8f8), to(#fffefe));
  background-image: linear-gradient(90deg, #f8f8f8 0%, #fffefe 100%);
  -webkit-box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
          box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
  color: #000000; }
  @media (min-width: 768px) {
    .page_white .main-nav__aside:after,
    .page_nti .main-nav__aside:after {
      border-right: 1px dotted #c3c3c3; } }
  .page_white .main-nav__main,
  .page_nti .main-nav__main {
    background: transparent; }
  .page_white .main-nav__item,
  .page_nti .main-nav__item {
    color: #000000; }

.page_white .pagination,
.page_nti .pagination {
  border-color: black; }
  .page_white .pagination__item,
  .page_nti .pagination__item {
    border-color: black;
    color: #000000; }

.page_white .popup .mfp-close,
.page_nti .popup .mfp-close {
  background: no-repeat center;
  background-image: url(sprite.png);
  background-position: -78px -168px;
  width: 26px;
  height: 26px; }

.page_white .role-selector__item,
.page_nti .role-selector__item {
  color: #000; }

.page_white .role-selector__link,
.page_nti .role-selector__link {
  background: url("../images/icons/checker-bg-2.png") no-repeat center; }

.page_white .role-selector__checker span,
.page_nti .role-selector__checker span {
  height: 20px;
  margin-top: 1px;
  margin-right: 1px;
  margin-left: 1px;
  background: url("../images/icons/checker-handler-2.png") no-repeat center; }

.page_white .user-nav__main,
.page_nti .user-nav__main {
  color: #2b2b2b; }

.page_white .checkbox__icon,
.page_nti .checkbox__icon {
  border-color: black; }

.page_white .checkbox input:checked + .checkbox__icon,
.page_nti .checkbox input:checked + .checkbox__icon {
  background-image: url(../images/icons/check-only-black.png); }

.page_white .search-field__field, .page_white .search-field__button,
.page_nti .search-field__field,
.page_nti .search-field__button {
  border-color: black; }

.page_white .icon_logout,
.page_nti .icon_logout {
  background-image: url(sprite.png);
  background-position: -251px -174px;
  width: 20px;
  height: 21px; }

.page_white .icon_ask,
.page_nti .icon_ask {
  background-image: url(sprite.png);
  background-position: -251px -46px;
  width: 23px;
  height: 23px; }

.page_white .icon_lk-empty,
.page_nti .icon_lk-empty {
  background-image: url(sprite.png);
  background-position: -128px 0px;
  width: 35px;
  height: 35px; }

.page_white .icon_fb,
.page_nti .icon_fb {
  background-image: url(sprite.png);
  background-position: -170px -142px;
  width: 20px;
  height: 20px; }

.page_white .icon_google,
.page_nti .icon_google {
  background-image: url(sprite.png);
  background-position: -276px -40px;
  width: 19px;
  height: 20px; }

.page_white .icon_twitter,
.page_nti .icon_twitter {
  background-image: url(sprite.png);
  background-position: -251px -112px;
  width: 25px;
  height: 20px; }

.page_white .icon_vk,
.page_nti .icon_vk {
  background-image: url(sprite.png);
  background-position: -128px -120px;
  width: 33px;
  height: 18px; }

.page_white .icon_ya,
.page_nti .icon_ya {
  background-image: url(sprite.png);
  background-position: -276px -177px;
  width: 10px;
  height: 20px; }

.page_white .icon_mail,
.page_nti .icon_mail {
  background-image: url(sprite.png);
  background-position: -251px -132px;
  width: 20px;
  height: 21px; }

.page_white .icon_bitbucket,
.page_nti .icon_bitbucket {
  background-image: url(sprite.png);
  background-position: -276px -60px;
  width: 18px;
  height: 20px; }

.page_white .icon_university2035,
.page_nti .icon_university2035 {
  background-image: url(sprite.png);
  background-position: -52px -239px;
  width: 20px;
  height: 20px; }

.page_white .icon_window,
.page_nti .icon_window {
  background-image: url(sprite.png);
  background-position: -172px -239px;
  width: 20px;
  height: 20px; }

.page_white .icon_github,
.page_nti .icon_github {
  background-image: url(sprite.png);
  background-position: -108px -74px;
  width: 20px;
  height: 20px; }

.page_white .icon_edit-layers,
.page_nti .icon_edit-layers {
  background-image: url(sprite.png);
  background-position: -92px -239px;
  width: 20px;
  height: 20px; }

.page_white .icon_trash,
.page_nti .icon_trash {
  background-image: url(sprite.png);
  background-position: -276px -120px;
  width: 16px;
  height: 20px; }

.page_white .icon_edit-users,
.page_nti .icon_edit-users {
  background-image: url(sprite.png);
  background-position: -253px -239px;
  width: 21px;
  height: 19px; }

.page_white .icon_edit-password,
.page_nti .icon_edit-password {
  background-image: url(sprite.png);
  background-position: -67px -221px;
  width: 34px;
  height: 17px; }

.page_white .icon_pencil,
.page_nti .icon_pencil {
  background-image: url(sprite.png);
  background-position: -192px -239px;
  width: 20px;
  height: 20px; }

.page_white .icon_update-password,
.page_nti .icon_update-password {
  background-image: url(sprite.png);
  background-position: -153px -221px;
  width: 32px;
  height: 13px; }

.page_white .icon_prev,
.page_nti .icon_prev {
  background-image: url(sprite.png);
  background-position: -271px -132px;
  width: 5px;
  height: 10px; }

.page_white .icon_next,
.page_nti .icon_next {
  background-image: url(sprite.png);
  background-position: -271px -153px;
  width: 5px;
  height: 10px; }

.page_white .icon_end-left,
.page_nti .icon_end-left {
  background-image: url(sprite.png);
  background-position: -211px -156px;
  width: 9px;
  height: 10px; }

.page_white .icon_end-right,
.page_nti .icon_end-right {
  background-image: url(sprite.png);
  background-position: -202px -156px;
  width: 9px;
  height: 10px; }

.page_white .icon_search,
.page_nti .icon_search {
  background-image: url(sprite.png);
  background-position: -78px -195px;
  width: 26px;
  height: 26px; }

.page_white .icon_clip,
.page_nti .icon_clip {
  background-image: url(sprite.png);
  background-position: -225px -156px;
  width: 24px;
  height: 25px; }

.page_white .add-link:before,
.page_nti .add-link:before {
  background-image: url(sprite.png);
  background-position: -225px -104px;
  width: 26px;
  height: 26px; }

.page_white .add-link_add-user:before,
.page_nti .add-link_add-user:before {
  background-image: url(sprite.png);
  background-position: -156px -168px;
  width: 26px;
  height: 26px; }

.page_white .add-link_remove-user:before,
.page_nti .add-link_remove-user:before {
  background-image: url(sprite.png);
  background-position: -26px -168px;
  width: 26px;
  height: 27px; }

.page_white .add-link_import-user:before,
.page_nti .add-link_import-user:before {
  background-image: url(sprite.png);
  background-position: -251px -23px;
  width: 25px;
  height: 23px; }

.page_white .sort-link:before,
.page_nti .sort-link:before {
  background-image: url(sprite.png);
  background-position: -225px -206px;
  width: 9px;
  height: 14px; }

.page_white .sort-link_up-active:before,
.page_nti .sort-link_up-active:before {
  background-image: url(sprite.png);
  background-position: -276px -247px;
  width: 9px;
  height: 14px; }

.page_white .sort-link_down-active:before,
.page_nti .sort-link_down-active:before {
  background-image: url(sprite.png);
  background-position: -286px -157px;
  width: 9px;
  height: 14px; }

.page_white .sort-link_down:before,
.page_nti .sort-link_down:before {
  background-image: url(sprite.png);
  background-position: -285px -247px;
  width: 9px;
  height: 14px; }

.page_white .pagination-nav__item,
.page_nti .pagination-nav__item {
  border-color: black; }
  .page_white .pagination-nav__item:before,
  .page_nti .pagination-nav__item:before {
    background-image: url(sprite.png);
    background-position: -211px -156px;
    width: 9px;
    height: 10px; }
  .page_white .pagination-nav__item:nth-child(2):before,
  .page_nti .pagination-nav__item:nth-child(2):before {
    background-image: url(sprite.png);
    background-position: -271px -132px;
    width: 5px;
    height: 10px; }
  .page_white .pagination-nav__item:nth-child(3):before,
  .page_nti .pagination-nav__item:nth-child(3):before {
    background-image: url(sprite.png);
    background-position: -271px -153px;
    width: 5px;
    height: 10px; }
  .page_white .pagination-nav__item:nth-child(4):before,
  .page_nti .pagination-nav__item:nth-child(4):before {
    background-image: url(sprite.png);
    background-position: -202px -156px;
    width: 9px;
    height: 10px; }

.page_white .expand__link:before,
.page_nti .expand__link:before {
  background-image: url(sprite.png);
  background-position: -276px -229px;
  width: 17px;
  height: 9px; }

.page_white .expand__link_active:before,
.page_nti .expand__link_active:before {
  background-image: url(sprite.png);
  background-position: -276px -220px;
  width: 17px;
  height: 9px; }

.page_white .expand__link_filter:before,
.page_nti .expand__link_filter:before {
  background-image: url(sprite.png);
  background-position: -251px -195px;
  width: 20px;
  height: 20px; }

.page_white .title-back,
.page_nti .title-back {
  color: black; }
  .page_white .title-back:before,
  .page_nti .title-back:before {
    background-image: url(sprite.png);
    background-position: -182px -168px;
    width: 26px;
    height: 26px; }

.page_white .document-upload,
.page_nti .document-upload {
  color: black; }
  .page_white .document-upload__file,
  .page_nti .document-upload__file {
    color: black; }

.page_white .data-table th,
.page_nti .data-table th {
  background-color: #fff2e7; }

.icon {
  display: inline-block;
  vertical-align: middle; }
  .icon_lk-empty {
    background-image: url(sprite.png);
    background-position: -128px -35px;
    width: 35px;
    height: 35px; }
  .icon_ask {
    background-image: url(sprite.png);
    background-position: -251px -69px;
    width: 23px;
    height: 23px; }
  .icon_logout {
    background-image: url(sprite.png);
    background-position: -276px 0px;
    width: 19px;
    height: 20px; }
  .icon_fb {
    background-image: url(sprite.png);
    background-position: -108px -97px;
    width: 20px;
    height: 20px; }
  .icon_google {
    background-image: url(sprite.png);
    background-position: -276px -20px;
    width: 19px;
    height: 20px; }
  .icon_twitter {
    background-image: url(sprite.png);
    background-position: -251px -92px;
    width: 25px;
    height: 20px; }
  .icon_vk {
    background-image: url(sprite.png);
    background-position: 0px -221px;
    width: 33px;
    height: 18px; }
  .icon_yandex {
    background-image: url(sprite.png);
    background-position: -54px -97px;
    width: 54px;
    height: 22px; }
  .icon_mail {
    background-image: url(sprite.png);
    background-position: -251px -153px;
    width: 20px;
    height: 21px; }
  .icon_update-password {
    background-image: url(sprite.png);
    background-position: -128px -105px;
    width: 32px;
    height: 13px; }
  .icon_edit-layers {
    background-image: url(sprite.png);
    background-position: -112px -239px;
    width: 20px;
    height: 20px; }
  .icon_trash {
    background-image: url(sprite.png);
    background-position: -276px -100px;
    width: 16px;
    height: 20px; }
  .icon_prev {
    background-image: url(sprite.png);
    background-position: -289px -197px;
    width: 5px;
    height: 10px; }
  .icon_next {
    background-image: url(sprite.png);
    background-position: -271px -142px;
    width: 5px;
    height: 10px; }
  .icon_end-left {
    background-image: url(sprite.png);
    background-position: -208px -168px;
    width: 9px;
    height: 10px; }
  .icon_end-right {
    background-image: url(sprite.png);
    background-position: -193px -156px;
    width: 9px;
    height: 10px; }
  .icon_edit-users {
    background-image: url(sprite.png);
    background-position: -232px -239px;
    width: 21px;
    height: 19px; }
  .icon_edit-layers {
    background-image: url(sprite.png);
    background-position: -112px -239px;
    width: 20px;
    height: 20px; }
  .icon_pencil {
    background-image: url(sprite.png);
    background-position: -72px -239px;
    width: 20px;
    height: 20px; }
  .icon_search {
    background-image: url(sprite.png);
    background-position: -52px -195px;
    width: 26px;
    height: 26px; }
  .icon_clip {
    background-image: url(sprite.png);
    background-position: -225px -181px;
    width: 24px;
    height: 25px; }

.btn {
  display: inline-block;
  vertical-align: middle;
  background: none;
  border-radius: 0;
  padding: 4px 7px;
  letter-spacing: 0.1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 116px;
  font-size: 14px;
  border: 1px solid #546475;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  text-decoration: none;
  text-align: center;
  color: #546475; }
  .btn .icon {
    margin-left: 15px; }
  .btn:hover {
    background: #546475;
    color: white; }
  .btn + .btn {
    margin-left: 15px; }
  .btn_orange {
    color: #e86d00;
    background-color: white;
    border: 1px solid #e86d00; }
    .btn_orange:hover {
      background: #e86d00;
      color: white; }
  .btn_default {
    border: 1px solid #cecece;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e6e6), to(#fffefe));
    background-image: linear-gradient(180deg, #e6e6e6 0%, #fffefe 100%);
    -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.83);
            box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.83);
    color: #474747;
    padding: 6px 19px;
    letter-spacing: 0px; }
    .btn_default:hover {
      background-image: -webkit-gradient(linear, left bottom, left top, from(#e6e6e6), to(#fffefe));
      background-image: linear-gradient(0deg, #e6e6e6 0%, #fffefe 100%);
      color: #474747; }
  .btn_search {
    border: 1px solid #bfbfbf;
    width: 22px;
    height: 22px;
    min-width: 0;
    background: white url(../images/icons/search-small.png) no-repeat center; }

.form__label {
  display: inline-block;
  vertical-align: top;
  color: #273748;
  font-size: 14px;
  line-height: 27px;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }
  @media (min-width: 768px) {
    .form__label {
      width: 25%; }
      .form__label_size-2 {
        width: 30%; }
      .form__label_size-3 {
        width: 44%; } }

.form__field {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .form__field_offset {
    padding-right: 35px;
    position: relative;
    vertical-align: top; }
  @media (min-width: 768px) {
    .form__field {
      width: 74%; }
      .form__field_offset {
        padding-right: 0; }
      .form__field_size-2 {
        width: 70%; }
      .form__field_size-3 {
        width: 56%; } }
  .form__field-wrap {
    position: relative; }
  .form__field-wrap + .form__field-wrap {
    margin-top: 20px; }

.form__row {
  margin-bottom: 18px;
  position: relative; }
  .form__row .btn + .link {
    vertical-align: middle;
    margin-left: 18px;
    font-size: 14px; }

.form__add-more {
  background-image: url(sprite.png);
  background-position: -225px 0px;
  width: 26px;
  height: 26px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 1px;
  display: block;
  right: -31px; }

.form__field_offset > .form__add-more {
  right: 0; }

.form__remove-row {
  width: 26px;
  height: 26px;
  position: absolute;
  right: -31px;
  top: 1px;
  display: block;
  background-image: url(sprite.png);
  background-position: -104px -168px;
  width: 26px;
  height: 26px; }

.input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  padding: 0 9px;
  height: 29px;
  background-color: white;
  border: 1px solid #e7e7e7;
  color: #273748;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px; }
  .input:disabled {
    background-color: #f1f2f2;
    border: 1px solid #e7e7e7; }
  .input_style_1 {
    margin: 0;
    height: 22px;
    padding-left: 4px;
    border: 1px solid #bfbfbf;
    margin-right: 15px; }

.textarea {
  padding: 9px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #e7e7e7;
  resize: none;
  min-height: 65px;
  font-size: 14px;
  font-family: "AkzidenzGroteskPro", sans-serif; }

.select {
  border: 1px solid #e7e7e7;
  position: relative; }
  .select:before {
    content: '';
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 5px solid #96a0ab;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    z-index: 0; }

select {
  height: 29px;
  background: transparent;
  position: relative;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 9px;
  padding-right: 20px;
  font-family: "AkzidenzGroteskPro", sans-serif;
  width: 100%;
  font-size: 14px;
  z-index: 1; }

select::-ms-expand {
  display: none; }

.checkbox {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-left: 22px;
  min-height: 17px; }
  .checkbox_big {
    padding-left: 36px;
    min-height: 26px; }
  .checkbox__input {
    height: 1px;
    width: 1px;
    position: absolute;
    z-index: -1;
    visibility: hidden; }
  .checkbox__icon {
    height: 17px;
    width: 17px;
    border: 1px solid #aab2bc;
    display: inline-block;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0; }
  .checkbox__text {
    display: inline-block;
    vertical-align: middle;
    color: #273748;
    font-size: 14px;
    line-height: 17px; }
  .checkbox_big .checkbox__icon {
    margin-right: 10px;
    width: 26px;
    height: 26px; }
  .checkbox_big .checkbox__text {
    line-height: 26px; }
  input:checked + .checkbox__icon {
    background-image: url(../images/icons/check-only.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 110%; }

.avatar {
  position: relative;
  display: inline-block; }
  .avatar__image {
    height: 72px;
    width: 73px;
    display: block;
    text-align: center;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden; }
    .avatar__image img {
      max-width: 100%;
      display: inline-block; }
  .avatar_empty .avatar__image {
    border-color: transparent; }
  .avatar__edit {
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #e86d00 url("../images/icons/pencil-white.png") no-repeat center;
    border-radius: 50%;
    cursor: pointer; }
  .avatar__input {
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1; }

.data-table {
  background-color: white;
  -webkit-box-shadow: 0 0 4.8px 0.3px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 4.8px 0.3px rgba(0, 0, 0, 0.06); }
  @media (min-width: 768px) {
    .data-table .add-link + .add-link {
      margin-left: 20px; } }
  .data-table__row {
    border: 1px solid #bcbdbd;
    border-top: none;
    padding: 5px 10px; }
    .data-table__row_no-border {
      border: none; }
    .data-table__row_no-offset {
      padding: 0; }
    .data-table__row_footer {
      font-size: 0; }
    .data-table__row_table-wrap {
      border: 1px solid #bcbdbd;
      border-bottom: none;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      @media (min-width: 1200px) {
        .data-table__row_table-wrap {
          overflow: visible; } }
    .data-table__row:last-child {
      margin-bottom: 0; }
  .data-table table {
    vertical-align: top;
    max-width: 100%;
    width: 100%;
    border-spacing: 0; }
    .data-table table input {
      border: 1px solid transparent;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 26px; }
    .data-table table select {
      height: 26px;
      width: 100%;
      font-size: 12px; }
    .data-table table .select {
      border-color: transparent; }
      .data-table table .select:before {
        display: none; }
  .data-table thead tr {
    border: 1px solid #bcbdbd;
    border-bottom: none; }
  .data-table th {
    text-align: left;
    background-color: #eaeff3;
    border-right: 1px solid #bcbdbd;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 4px;
    height: 46px;
    color: #273748;
    font-weight: 500;
    line-height: 18px; }
    @media (min-width: 1200px) {
      .data-table th {
        padding: 0 10px;
        height: 43px; } }
    .data-table th a {
      color: #273748;
      font-weight: 500;
      line-height: 18px;
      text-decoration: none;
      display: block; }
    .data-table th:last-child {
      border: none; }
  .data-table td {
    height: 46px;
    padding: 0 10px;
    border-top: 1px solid #bcbdbd;
    border-right: 1px solid #bcbdbd;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #333;
    font-size: 12px;
    line-height: 17px; }
    @media (min-width: 1200px) {
      .data-table td {
        height: 36px; } }
    .data-table td:last-child {
      border-right: none; }
  .data-table tbody tr {
    border-left: 1px solid #bcbdbd;
    border-right: 1px solid #bcbdbd; }
    .data-table tbody tr:last-child td {
      border-bottom: 1px solid #bcbdbd; }
  .data-table tbody tr:hover {
    position: relative;
    background-color: #fdefe2;
    border-color: #e86d00; }
    .data-table tbody tr:hover td {
      border-bottom: 1px solid #e86d00 !important;
      border-top: 1px solid #e86d00; }
    .data-table tbody tr:hover input {
      border: 1px solid #e7e7e7; }
    .data-table tbody tr:hover .select {
      border: 1px solid #e7e7e7;
      background: white; }
      .data-table tbody tr:hover .select:before {
        display: block; }
  .data-table__pagination {
    display: inline-block;
    vertical-align: middle;
    width: 100%; }
    @media (min-width: 768px) {
      .data-table__pagination {
        width: 40%; } }
  .data-table__output-info {
    display: inline-block;
    vertical-align: middle;
    width: 100%; }
    @media (min-width: 768px) {
      .data-table__output-info {
        width: 60%;
        text-align: right; } }
  .data-table__controls a {
    display: inline-block;
    width: 30%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-right: 10px;
    margin-top: 5px;
    vertical-align: middle; }
    .data-table__controls a img {
      max-width: 100%; }
    @media (min-width: 1200px) {
      .data-table__controls a {
        width: auto; } }
    .data-table__controls a:last-child {
      margin-right: 0; }
  .data-table .checkbox__icon {
    margin: 0; }
  .data-table td span + a {
    margin-left: 10px; }

.document-upload__file {
  color: #728496;
  font-size: 12px;
  margin-bottom: 5px; }
  .document-upload__file img, .document-upload__file a, .document-upload__file span {
    vertical-align: middle; }
  .document-upload__file-name {
    margin-left: 10px;
    margin-right: 5px; }

.document-upload__label {
  margin-top: 15px;
  display: inline-block; }
  .document-upload__label input {
    outline: none;
    margin-left: 10px; }
  .document-upload__label .add-link span {
    margin-left: 10px; }

.content-nav {
  border: 1px solid #e7e7e7;
  border-left: none;
  border-right: none;
  font-size: 0;
  padding: 20px 20px 0 20px; }
  @media (min-width: 768px) {
    .content-nav {
      padding-top: 10px;
      padding-bottom: 10px; } }
  .content-nav__item {
    color: #546475;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    text-decoration: none;
    height: 45px;
    display: inline-block;
    vertical-align: top;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-top: 30px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .content-nav__item {
        margin-bottom: 0; } }
    .content-nav__item:last-child {
      margin-right: 0; }
    .content-nav__item[class*='active'] {
      color: #e86d00; }
    .content-nav__item_groups {
      background: url(../images/icons/menu/group.png) no-repeat center top; }
    .content-nav__item_groups_active {
      background: url(../images/icons/menu/group-active.png) no-repeat center top; }
    .content-nav__item_owner {
      background: url(../images/icons/menu/owner.png) no-repeat center top; }
    .content-nav__item_password {
      background: url(../images/icons/menu/password.png) no-repeat center top; }
    .content-nav__item_password_active {
      background: url(../images/icons/menu/password-active.png) no-repeat center top; }
    .content-nav__item_owner_active {
      background: url(../images/icons/menu/owner-active.png) no-repeat center top; }
    .content-nav__item_user {
      background: url(../images/icons/menu/user.png) no-repeat center top; }
    .content-nav__item_user_active {
      background: url(../images/icons/menu/user-active.png) no-repeat center top; }
    .content-nav__item_ierarchy {
      background: url(../images/icons/menu/ierarchy.png) no-repeat center top; }
    .content-nav__item_ierarchy_active {
      background: url(../images/icons/menu/ierarchy-active.png) no-repeat center top; }
    .content-nav__item_user-remove {
      background: url(../images/icons/menu/user-remove.png) no-repeat center top; }
    .content-nav__item_user-remove_active {
      background: url(../images/icons/menu/user-remove-active.png) no-repeat center top; }

.foreign-auth {
  font-family: 'Open Sans', sans-serif;
  background: white; }
  .foreign-auth__header {
    height: 150px;
    background: url(../images/foreign-auth/header-bg.jpg) no-repeat top center; }
    @media (min-width: 768px) {
      .foreign-auth__header {
        height: 200px; } }
    @media (min-width: 992px) {
      .foreign-auth__header {
        height: 250px; } }
  .foreign-auth__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
    color: #352e41; }
  .foreign-auth__company-logo {
    width: 120px;
    height: 120px;
    background: white url(../images/foreign-auth/company-logo.svg) no-repeat center;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -60px;
    margin-bottom: 20px; }
  .foreign-auth__content {
    max-width: 345px;
    margin: 0 auto; }
  .foreign-auth__text {
    margin-top: 40px;
    font-size: 14px;
    text-align: center;
    color: #352e41;
    line-height: 1.2em; }
  .foreign-auth__social-auth {
    margin-top: 20px; }
  .foreign-auth__footer {
    margin-top: 50px;
    height: 80px;
    background-color: #f5f5f5; }

.social-auth {
  font-size: 0;
  text-align: center; }
  .social-auth__item {
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 60px;
    margin-right: 20px; }
    .social-auth__item:last-child {
      margin-right: 0; }
    .social-auth__item_fb {
      background: url(../images/foreign-auth/fb.svg) no-repeat center; }
    .social-auth__item_google {
      background: url(../images/foreign-auth/google.svg) no-repeat center; }
    .social-auth__item_yandex {
      background: url(../images/foreign-auth/yandex.svg) no-repeat center; }
    .social-auth__item_vk {
      background: url(../images/foreign-auth/vk.svg) no-repeat center; }

.lang-selector {
  font-size: 14px; }
  .lang-selector__item {
    color: #fefeff;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px; }
    .lang-selector__item:last-child {
      margin-right: 0; }
  .lang-selector span {
    font-weight: 500; }
  .lang-selector a {
    font-weight: 400;
    text-decoration: underline; }

.main-footer {
  background-color: #273748;
  padding: 17px 0; }
  .main-footer__text {
    color: #dae1e8;
    font-size: 12px;
    font-weight: 500; }

.main-header {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(51, 73, 96, 0.7)), to(rgba(51, 73, 96, 0.7))), url("../images/header-bg.jpg");
  background-image: linear-gradient(to right, rgba(51, 73, 96, 0.7) 0%, rgba(51, 73, 96, 0.7) 100%), url("../images/header-bg.jpg");
  background-size: cover; }
  .main-header .page-layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .main-header__logo {
    font-size: 0;
    vertical-align: middle;
    display: inline-block;
    max-width: 320px; }
    .main-header__logo img {
      display: inline-block;
      vertical-align: middle; }
    .main-header__logo span {
      vertical-align: middle;
      color: white;
      font-size: 18px;
      font-weight: 500;
      margin-left: 10px;
      display: none; }
    @media (min-width: 768px) {
      .main-header__logo .full_name {
        display: inline-block; } }
    @media (max-width: 768px) {
      .main-header__logo .short_name {
        display: inline-block; } }
  .main-header__aside {
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: middle;
    text-align: right;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    @media (min-width: 768px) {
      .main-header__aside {
        width: calc(100% - 320px); } }
  .main-header__langs {
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: middle; }
  .main-header__user-nav {
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: middle;
    margin-left: 25px; }
    @media (min-width: 768px) {
      .main-header__user-nav {
        margin-left: 38px; } }
  .main-header__info-nav {
    margin-top: 6px;
    margin-right: 16px; }
    .main-header__info-nav:last-child {
      margin-right: 0; }

.main-nav {
  background-color: #273748;
  position: relative;
  line-height: 0;
  height: 45px; }
  @media (min-width: 768px) {
    .main-nav {
      overflow: hidden; } }
  @media (min-width: 768px) {
    .main-nav .page-layout {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }
  .main-nav__aside {
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
    min-width: 310px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .main-nav__aside .role-selector {
      margin-top: 2px; }
    @media (min-width: 768px) {
      .main-nav__aside {
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
        padding-right: 32px;
        margin-right: 12px;
        position: relative; }
        .main-nav__aside:after {
          content: '';
          position: absolute;
          right: 0;
          top: 7px;
          height: 30px;
          border-right: 1px dotted white; } }
  .main-nav__main {
    margin-left: -20px;
    vertical-align: middle;
    position: absolute;
    left: 0;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #273748;
    text-align: center;
    display: none;
    z-index: 200; }
    .main-nav__main_active {
      display: block;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    @media (min-width: 768px) {
      .main-nav__main {
        position: relative;
        overflow: auto;
        left: auto;
        width: auto;
        display: block; } }
  .main-nav__main-wrap {
    max-height: calc(100vh - 120px);
    overflow: scroll; }
    @media (min-width: 768px) {
      .main-nav__main-wrap {
        display: block;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: visible;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        padding-top: 5px;
        padding-bottom: 30px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  .main-nav__aside + .main-nav__main {
    margin-left: 0; }
  .main-nav__item {
    font-size: 14px;
    color: white;
    height: 40px;
    line-height: 35px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration: none;
    padding: 0 20px;
    display: block;
    margin: 0 auto;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%; }
    @media (min-width: 768px) {
      .main-nav__item {
        display: inline-block;
        width: auto;
        margin: 0; } }
    .main-nav__item_active {
      color: #e86d00;
      position: relative;
      width: auto;
      display: inline-block; }
      .main-nav__item_active:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: #e86d00; }
  .main-nav__menu-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-bottom: 2px solid white;
    width: 22px;
    height: 18px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
    display: inline-block; }
    .main-nav__menu-toggle:after, .main-nav__menu-toggle:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: white;
      top: 0;
      left: 0;
      -webkit-transition: -webkit-transform .5s ease;
      transition: -webkit-transform .5s ease;
      transition: transform .5s ease;
      transition: transform .5s ease, -webkit-transform .5s ease; }
    .main-nav__menu-toggle:after {
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: 1px; }
    .main-nav__menu-toggle_active {
      border: none; }
      .main-nav__menu-toggle_active:after, .main-nav__menu-toggle_active:before {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg); }
      .main-nav__menu-toggle_active:after {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
        margin-top: 0; }
    @media (min-width: 768px) {
      .main-nav__menu-toggle {
        display: none; } }

.pagination {
  background-color: white;
  border: 1px solid #bcbdbd;
  display: inline-block;
  vertical-align: top;
  font-size: 0; }
  .pagination__item {
    position: relative;
    border-right: 1px solid #bcbdbd;
    display: inline-block;
    vertical-align: top;
    min-width: 20px;
    height: 20px;
    padding-right: 6px;
    padding-left: 6px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #273748;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration: none; }
    .pagination__item_active {
      background-color: #8a95a1;
      color: white; }
    .pagination__item:last-child {
      border-right: none; }
    .pagination__item img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.output-info__dropdown {
  color: #3d3d3d;
  font-size: 12px;
  line-height: 31px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle; }

.output-info__select {
  border: none;
  background: transparent;
  color: #3d3d3d;
  display: inline-block;
  margin-left: 10px;
  font-weight: 500; }
  .output-info__select select {
    font-size: 12px;
    padding-right: 12px; }

.output-info__text {
  color: #3d3d3d;
  font-size: 12px;
  line-height: 31px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px; }

.output-info__text + .output-info__dropdown {
  margin-left: 30px; }

.pagination-nav {
  font-size: 0; }
  .pagination-nav__item {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #606f7f;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative; }
    .pagination-nav__item:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background-image: url(sprite.png);
      background-position: -208px -168px;
      width: 9px;
      height: 10px; }
    .pagination-nav__item:last-child {
      margin-right: 0; }
    .pagination-nav__item:nth-child(2):before {
      background-image: url(sprite.png);
      background-position: -289px -197px;
      width: 5px;
      height: 10px; }
    .pagination-nav__item:nth-child(3):before {
      background-image: url(sprite.png);
      background-position: -271px -142px;
      width: 5px;
      height: 10px; }
    .pagination-nav__item:nth-child(4):before {
      background-image: url(sprite.png);
      background-position: -193px -156px;
      width: 9px;
      height: 10px; }

.panels-block {
  font-size: 0;
  color: #273748; }
  .panels-block__info {
    font-weight: 500;
    font-size: 14px;
    padding-right: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .panels-block__info {
        margin-bottom: 0;
        width: 56%; } }
  .panels-block__desc {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle; }
    @media (min-width: 768px) {
      .panels-block__desc {
        width: 44%; } }
  .panels-block__main {
    width: 320px;
    display: inline-block;
    vertical-align: middle; }
  .panels-block__row {
    border: 1px solid #e7e7e7;
    border-bottom: none;
    padding: 12px 10px; }
    .panels-block__row:last-child {
      border: 1px solid #e7e7e7; }
  .panels-block__avatar {
    display: inline-block;
    vertical-align: middle;
    width: 45px;
    height: 45px;
    border-radius: 50%; }
    .panels-block__avatar img {
      max-width: 100%; }
  .panels-block__name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; }

.popup {
  margin: 0 auto;
  max-width: 564px;
  background-color: white;
  border: 1px solid #cecece;
  -webkit-box-shadow: 0 0 23.4px 8.6px rgba(126, 126, 126, 0.75);
          box-shadow: 0 0 23.4px 8.6px rgba(126, 126, 126, 0.75);
  position: relative; }
  .popup_wide {
    max-width: 790px; }
  .popup .mfp-close {
    font-size: 0;
    background-image: url(sprite.png);
    background-position: -104px -168px;
    width: 26px;
    height: 26px;
    margin-top: 10px;
    margin-right: 10px; }
  .popup__header {
    padding: 12px;
    border-bottom: 1px solid #cecece; }
  .popup__title {
    margin: 0;
    padding-right: 50px; }
  .popup__content {
    padding: 12px; }
    .popup__content_offset_2 {
      padding-top: 35px;
      padding-left: 20px; }
    .popup__content .form__label {
      padding-right: 10px;
      width: 100%; }
      @media (min-width: 768px) {
        .popup__content .form__label {
          width: 167px; } }
    .popup__content .form__field {
      width: 100%; }
      @media (min-width: 768px) {
        .popup__content .form__field {
          width: 67%; } }
    .popup__content .form__row:last-child {
      margin-bottom: 0; }
  .popup__license {
    max-height: 320px;
    background-color: white;
    border: 1px solid #e7e7e7;
    width: 100%;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-right: 40px; }
  .popup__inner {
    border: 1px solid #e7e7e7;
    padding: 10px; }
    .popup__inner_style_1 {
      border: 1px solid #dadada;
      background-color: #fcfcfe; }

.mfp-bg {
  display: none; }

.role-selector__item {
  color: white;
  text-decoration: none;
  letter-spacing: 0.3px;
  margin-right: 8px; }
  .role-selector__item_active {
    color: #e86d00; }
  .role-selector__item:last-child {
    margin-right: 0; }

.role-selector__link {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url("../images/icons/checker-bg.png") no-repeat center;
  z-index: 1; }

.role-selector__checker {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: 23px;
  width: 54px;
  margin-right: 10px;
  text-align: right; }
  .role-selector__checker span {
    background: url("../images/icons/checker-handler.png") no-repeat center;
    height: 24px;
    width: 27px;
    background-size: cover;
    display: inline-block;
    z-index: 2;
    position: relative; }

.role-selector__item_active + .role-selector__checker {
  text-align: left; }

.socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .socials_with-controls {
    padding-top: 10px; }
  .socials__item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    position: relative; }
    @media (min-width: 768px) {
      .socials__item {
        margin-right: 25px; } }
    .socials__item:last-child {
      margin-right: 0; }
    .socials__item_disabled img {
      opacity: 0.5; }
    .socials__item_fb {
      background-image: url(sprite.png);
      background-position: -108px -97px;
      width: 20px;
      height: 20px; }
    .socials__item_google {
      background-image: url(sprite.png);
      background-position: -276px -20px;
      width: 19px;
      height: 20px; }
    .socials__item_twitter {
      background-image: url(sprite.png);
      background-position: -251px -92px;
      width: 25px;
      height: 20px; }
    .socials__item_vk {
      background-image: url(sprite.png);
      background-position: 0px -221px;
      width: 33px;
      height: 18px; }
    .socials__item_ya {
      background-image: url(sprite.png);
      background-position: -54px -97px;
      width: 54px;
      height: 22px; }
    .socials__item_mail {
      background-image: url(sprite.png);
      background-position: -251px -153px;
      width: 20px;
      height: 21px; }
    .socials__item_bitbucket {
      background-image: url(sprite.png);
      background-position: -276px -60px;
      width: 18px;
      height: 20px; }
    .socials__item_university2035 {
      background-image: url(sprite.png);
      background-position: -132px -239px;
      width: 20px;
      height: 20px; }
    .socials__item_window {
      background-image: url(sprite.png);
      background-position: -172px -239px;
      width: 20px;
      height: 20px; }
    .socials__item_github {
      background-image: url(sprite.png);
      background-position: -108px -74px;
      width: 20px;
      height: 20px; }
  .socials__control {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 17px;
    height: 17px;
    background: #e86d00 url(../images/icons/plus-small.png) no-repeat center;
    border-radius: 50%;
    display: block;
    z-index: 1; }
    .socials__control_remove {
      background-image: url(../images/icons/cancel-small.png); }

.subgroup + .subgroup {
  margin-top: 20px; }

.subgroup__header {
  margin-bottom: 10px;
  font-size: 14px; }

@media (min-width: 768px) {
  .subgroup__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; } }

.subgroup__text {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px; }

.subgroup__add-link, .subgroup__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  margin-bottom: 5px; }
  @media (min-width: 768px) {
    .subgroup__add-link, .subgroup__label {
      width: 140px;
      margin-bottom: 0; } }

.subgroup__row {
  margin-bottom: 20px; }
  .subgroup__row:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .subgroup__row {
      margin-bottom: 10px; } }

.subgroup__content {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100%; }
  .subgroup__content_height-1 {
    max-height: 110px; }
  .subgroup__content_height-2 {
    max-height: 220px; }
  .subgroup__content_inner .nano-content {
    padding-left: 30px; }

.subgroup__pagination {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .subgroup__pagination {
      margin-bottom: 0;
      width: 140px; } }

.subgroup__output-info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-left: -10px; }
  @media (min-width: 768px) {
    .subgroup__output-info {
      margin-left: 0;
      text-align: right;
      width: calc(100% - 135px); } }

.subgroup .nano .nano-content {
  padding-left: 0; }

.subgroup__col {
  width: 47%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .subgroup__col_full {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-right: 0; }

.search-field {
  font-size: 0; }
  .search-field__field {
    height: 35px;
    background-color: white;
    border: 1px solid #546475;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 35px);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-right: none; }
    .search-field__field input {
      height: 32px;
      width: 100%;
      font-size: 14px;
      padding: 5px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border: none; }
  .search-field__button {
    padding: 0;
    text-decoration: none;
    width: 35px;
    height: 35px;
    border: 1px solid #546475;
    display: inline-block;
    vertical-align: top;
    border-radius: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
    position: relative; }

.user-nav__main {
  display: inline-block;
  vertical-align: middle;
  color: white;
  font-weight: 400;
  font-size: 14px;
  padding-right: 10px;
  letter-spacing: 0.1px;
  position: relative;
  text-decoration: none;
  margin-right: 20px; }
  .user-nav__main:before {
    content: '';
    height: 46px;
    border-right: 1px dashed currentColor;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (min-width: 768px) {
    .user-nav__main {
      padding-right: 25px;
      font-size: 16px; } }

.user-nav__ask {
  font-size: 0;
  display: inline-block;
  vertical-align: middle; }

.user-nav__logout {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  width: 20px;
  height: 20px;
  position: relative; }

.user-nav__ask + .user-nav__logout {
  margin-left: 20px; }

.user-nav__avatar {
  vertical-align: middle;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden; }
  @media (min-width: 570px) {
    .user-nav__avatar {
      margin-right: 14px; } }

.user-nav__name {
  display: none;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30vw;
  text-align: left; }
  @media (min-width: 570px) {
    .user-nav__name {
      display: inline-block;
      max-width: 20vw; } }

.user-nav__empty {
  color: white;
  font-size: 14px; }
  .user-nav__empty a {
    font-weight: 500;
    text-decoration: underline;
    color: white;
    margin-left: 10px; }
  @media (max-width: 576px) {
    .user-nav__empty a,
    .user-nav__empty span {
      display: block; } }

.user-nav__lang {
  margin-right: 40px; }

.tac {
  text-align: center; }

.db {
  display: block; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.nowrap {
  white-space: nowrap; }

.breakword {
  word-break: break-all; }

.show-password {
  font-size: 14px; }
  .show-password__password {
    margin-left: 10px;
    display: none; }
  .show-password__icon {
    background: url("../images/icons/password.png") no-repeat center;
    width: 26px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .show-password__text {
    vertical-align: middle;
    color: #273748; }
  .show-password__toggle {
    padding: 0;
    border: none;
    cursor: pointer;
    background: transparent; }
  .show-password__toggle_show +
.show-password__password {
    display: inline-block; }
  .show-password__toggle_show .show-password__icon {
    background-image: url("../images/icons/password-hide.png"); }

/*# sourceMappingURL=main.css.map */
